import React from "react";

function About() {
  return (
    <section className="features">
      <div className="features__container container container--size-large">
        <div className="row">
          <div className="col-12 col-md-0 col-lg-4"></div>
          <div className="col-12 col-md-12 col-lg-8">
            {/* <div className="features__description" data-aos="fade-up">Our intelligent digital strategy and a pragmatic and thoughtful approach to solving business calls deliver a successful framework for both you and your audience.</div> */}
            <div className="features__description" data-aos="fade-up">
              In this fast-PACED world, passion takes you farther.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 col-lg-5">
            <a
              className="features__card features__card--size-large feature-card"
              href="#/"
              data-aos="fade-up"
            >
              <span className="feature-card__image">
                <img
                  src="../../../assets/img/picture/home/feature-1.jpg"
                  width="810"
                  height="529"
                  alt="questions"
                />
              </span>
              <span
                className="feature-card__caption"
                style={{ padding: "10px" }}
              >
                We are <br />
                Pace
              </span>
            </a>
          </div>
          <div className="col-12 col-md-4 col-lg-7">
            <div className="features__text" data-aos="fade-up">
              <b>
                <h1>
                  We are Nigeria's leading passion-based marketing company.
                </h1>
              </b>{" "}
              <br /> We are a sports and entertainment marketing agency
              specializing in making customers passionate about your brand.{" "}
              <br /> <br />
              Passion, precision, and speed put everyone one step ahead of
              competitors, so we tap into this compelling, superhuman force to
              deliver our clients' uniquely engaging stories to drive business.
              We only lead projects that our clients and ourselves are
              passionate about because our passion is like a contagious fire
              that ignites investors, business partners, and customers.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-5 d-flex">
                <a
                  className="features__card features__card--size-small feature-card feature-card--mobile-same-size"
                  href="#/"
                  data-aos="fade-up"
                >
                  <span className="feature-card__image">
                    <img
                      src="../../../assets/img/picture/home/feature-3.jpg"
                      width="410"
                      height="490"
                      alt="Concept"
                    />
                  </span>
                  <span className="feature-card__caption">
                    PACE
                    <br />
                    PRECISION
                  </span>
                </a>
              </div>
              <div className="col-12 col-md-4 col-lg-7">
                <a
                  className="features__card features__card--size-middle feature-card feature-card--mobile-same-size"
                  href="#/"
                  data-aos="fade-up"
                >
                  <span className="feature-card__image">
                    <img
                      src="../../../assets/img/picture/home/feature-2.jpg"
                      width="616"
                      height="529"
                      alt="Goal"
                    />
                  </span>
                  <span className="feature-card__caption">
                    WHAT WE ARE
                    <br />
                    GREAT AT!
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
