import React, { Fragment } from "react";
import NavHelper from "../../Helper/NavHelper";
// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
// const ScrollDemo = () => {

//   const myRef = useRef(null)
//   const executeScroll = () => scrollToRef(myRef)

class Header extends NavHelper {
  render() {
    return (
      <Fragment>
        <header className="header header--secondary header--absolute __js_fixed-header header--height ">
          <div className="header__container container container--size-large">
            {/* <a className="header__logo logo" href='#/'>
                  <svg width="76" height="19">
                  </svg>
                </a> */}
            <div className="header__mobile mobile-canvas">
              <nav className="mobile-canvas__nav navigation">
                <ul className="navigation__list">
                  <li className="navigation__item navigation__item--current">
                    <a className="navigation__link" href="/">
                      Home
                      <span className="navigation__link-icon">
                        <svg width="12" height="13">
                          {/* <use xlink:href="#link-arrow"></use> */}
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li className="navigation__item">
                    <a
                      className="navigation__link animsition-link"
                      href="/about"
                    >
                      About Us
                      <span className="navigation__link-icon">
                        <svg width="12" height="13">
                          {/* <use xlink:href="#link-arrow"></use> */}
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li className="navigation__item">
                    <a
                      className="navigation__link animsition-link"
                      href="/#our-services"
                    >
                      Services
                      <span className="navigation__link-icon">
                        <svg width="12" height="13">
                          {/* <use xlink:href="#link-arrow"></use> */}
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li className="navigation__item">
                    <a
                      className="navigation__link animsition-link"
                      href="/#assets"
                    >
                      Assets
                      <span className="navigation__link-icon">
                        <svg width="12" height="13">
                          {/* <use xlink:href="#link-arrow"></use> */}
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li className="navigation__item">
                    {/* <Link to="/contact" className="navigation__link animsition-link">Contact <span className="navigation__link-icon">
                            <svg width="12" height="13">
                               <use xlink:href="#link-arrow"></use> 
                            </svg>
                          </span> 
                          </Link> */}
                    <a
                      className="navigation__link animsition-link"
                      href="/contact"
                    >
                      Contact
                      <span className="navigation__link-icon">
                        <svg width="12" height="13">
                          {/* <use xlink:href="#link-arrow"></use> */}
                        </svg>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
              <a className="mobile-canvas__phone" href="tel:+34255216021">
                +234 703 749 1080
              </a>
            </div>
            <button className="header__menu-toggle menu-toggle" type="button">
              <span className="menu-toggle__line"></span>
              <span className="visually-hidden">Menu toggle</span>
            </button>
          </div>
        </header>
      </Fragment>
    );
  }
}
export default Header;
