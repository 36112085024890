import React from "react";

function Service() {
  return (
    <section className="our-services" id="our-services">
      <div className="our-services__container container container--size-large">
        <header
          className="our-services__header our-services__header--large"
          data-aos="fade-up"
        >
          <div className="row">
            <div
              className="col-12 col-md-6 col-xl-5 col-xxl-3"
              data-aos="fade-up"
            >
              <h2 className="our-services__title our-services__title--upper">
                Our Services
              </h2>
            </div>
            {/* <div className="col-12 col-md-12 col-xl-5 col-xxl-6 order-md-1 order-xl-0" data-aos="fade-up" >
                <div className="our-services__short">We’ve worked with a wide array of clients across the globe to apply design fundamentals of elegance, simplicity</div>
              </div> */}
            <div
              className="col-12 col-md-auto col-xl-5 col-xxl-6 ml-auto order-md-1 order-xl-0 text-md-right"
              data-aos="fade-up"
            >
              <div className="our-services__short">
                We’ve worked with a wide array of clients across the globe to
                apply design fundamentals of elegance, simplicity
              </div>
            </div>
            {/* <div className="col-12 col-md-auto col-xl-2 col-xxl-3 ml-auto text-md-right" data-aos="fade-up" >
                <a className="our-services__more our-services__more--negative-top arrow-link--white arrow-link" href="services.html" data-aos="fade-up">
                  <span className="arrow-link__text">View all</span>
                  <span className="arrow-link__icon">
                    <svg width="75" height="75">
                      <use xlink:href="#link-arrow"></use>
                    </svg>
                  </span>
                </a>
              </div> */}
          </div>
        </header>
        <div className="our-services__accordion accordion">
          <div className="accordion__item" data-aos="fade-up">
            <button className="accordion__item-header" type="button">
              <span className="row align-items-md-center">
                <span className="accordion__item-title col-11 col-md-5">
                  Advisory/Training
                </span>
                <span className="accordion__item-short col-11 col-md-6">
                  Research & Insights; Sponsorship Selection and Negotiation;
                  Sponsorship Portfolio Analysis; Strategic Planning and Event
                  Creation; Events Feasibility & Bidding and Return on
                  Investment (ROI) Measurement.
                </span>
              </span>
            </button>
            <div className="accordion__item-body">
              <div className="row">
                <div className="accordion__item-left col-12 col-md-6 col-lg-5">
                  <img
                    src="img/picture/home/accordion-large.jpg"
                    width="810"
                    height="530"
                    alt="Concept"
                  />
                </div>
                <div className="accordion__item-right col-12 col-md-6 col-lg-7">
                  <img
                    src="img/picture/home/accordion-small.jpg"
                    width="348"
                    height="287"
                    alt="Concept"
                  />
                  <div className="accordion__item-text">
                    In the design process, they create both functional and
                    beautiful things. The team possesses unique individuality
                    and strong qualifications and can easily translate something
                    so abstract and visionary into a digital experience. They
                    always put the clients first no matter how complicated the
                    tasks are.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion__item" data-aos="fade-up">
            <button className="accordion__item-header" type="button">
              <span className="row align-items-md-center">
                <span className="accordion__item-title col-11 col-md-5">
                  Rights
                </span>
                <span className="accordion__item-short col-11 col-md-6">
                  Property Representation; Film Promotions/ Movie Production
                  Sponsorship; Media Rights Negotiation and Management; Image
                  Rights Management; Partnership Negotiation; Talent Management
                  (Athlete/Artiste) and Broadcast.
                </span>
              </span>
            </button>
            <div className="accordion__item-body">
              <div className="row">
                <div className="accordion__item-left col-12 col-md-6 col-lg-5">
                  <img
                    src="img/picture/home/accordion-large.jpg"
                    width="810"
                    height="530"
                    alt="Concept"
                  />
                </div>
                <div className="accordion__item-right col-12 col-md-6 col-lg-7">
                  <img
                    src="img/picture/home/accordion-small.jpg"
                    width="348"
                    height="287"
                    alt="Concept"
                  />
                  <div className="accordion__item-text">
                    In the design process, they create both functional and
                    beautiful things. The team possesses unique individuality
                    and strong qualifications and can easily translate something
                    so abstract and visionary into a digital experience. They
                    always put the clients first no matter how complicated the
                    tasks are.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion__item" data-aos="fade-up">
            <button className="accordion__item-header" type="button">
              <span className="row align-items-md-center">
                <span className="accordion__item-title col-11 col-md-5">
                  Communications
                </span>
                <span className="accordion__item-short col-11 col-md-6">
                  Branding; Social media & PR; Content; Digital; Creative and
                  Design.
                </span>
              </span>
            </button>
            <div className="accordion__item-body">
              <div className="row">
                <div className="accordion__item-left col-12 col-md-6 col-lg-5">
                  <img
                    src="img/picture/home/accordion-large.jpg"
                    width="810"
                    height="530"
                    alt="Concept"
                  />
                </div>
                <div className="accordion__item-right col-12 col-md-6 col-lg-7">
                  <img
                    src="img/picture/home/accordion-small.jpg"
                    width="348"
                    height="287"
                    alt="Concept"
                  />
                  <div className="accordion__item-text">
                    In the design process, they create both functional and
                    beautiful things. The team possesses unique individuality
                    and strong qualifications and can easily translate something
                    so abstract and visionary into a digital experience. They
                    always put the clients first no matter how complicated the
                    tasks are.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="accordion__item" data-aos="fade-up">
              <button className="accordion__item-header" type="button">
                <span className="row align-items-md-center">
                  <span className="accordion__item-title col-11 col-md-5">Experiential</span>
                  <span className="accordion__item-short col-11 col-md-6">Hospitality; Events; Production; Games; Premieres; Screenings; Concerts and Tours.</span>
                </span>
              </button>
              <div className="accordion__item-body">
                <div className="row">
                  <div className="accordion__item-left col-12 col-md-6 col-lg-5">
                    <img src="img/picture/home/accordion-large.jpg" width="810" height="530" alt="Concept" />
                  </div>
                  <div className="accordion__item-right col-12 col-md-6 col-lg-7">
                    <img src="img/picture/home/accordion-small.jpg" width="348" height="287" alt="Concept" />
                    <div className="accordion__item-text">In the design process, they create both functional and beautiful things. The team possesses unique individuality and strong qualifications and can easily translate something so abstract and visionary into a digital experience. They always put the clients first no matter how complicated the tasks are.</div>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="accordion__item" data-aos="fade-up">
            <button className="accordion__item-header" type="button">
              <span className="row align-items-md-center">
                <span className="accordion__item-title col-11 col-md-5">
                  Merchandising
                </span>
                <span className="accordion__item-short col-11 col-md-6">
                  Manufacturing and Retail.
                </span>
              </span>
            </button>
            <div className="accordion__item-body">
              <div className="row">
                <div className="accordion__item-left col-12 col-md-6 col-lg-5">
                  <img
                    src="img/picture/home/accordion-large.jpg"
                    width="810"
                    height="530"
                    alt="Concept"
                  />
                </div>
                <div className="accordion__item-right col-12 col-md-6 col-lg-7">
                  <img
                    src="img/picture/home/accordion-small.jpg"
                    width="348"
                    height="287"
                    alt="Concept"
                  />
                  <div className="accordion__item-text">
                    In the design process, they create both functional and
                    beautiful things. The team possesses unique individuality
                    and strong qualifications and can easily translate something
                    so abstract and visionary into a digital experience. They
                    always put the clients first no matter how complicated the
                    tasks are.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion__item" data-aos="fade-up">
            <button className="accordion__item-header" type="button">
              <span className="row align-items-md-center">
                <span className="accordion__item-title col-11 col-md-5">
                  Distribution
                </span>
                <span className="accordion__item-short col-11 col-md-6">
                  Financing, Promotions and Sales.
                </span>
              </span>
            </button>
            <div className="accordion__item-body">
              <div className="row">
                <div className="accordion__item-left col-12 col-md-6 col-lg-5">
                  <img
                    src="img/picture/home/accordion-large.jpg"
                    width="810"
                    height="530"
                    alt="Concept"
                  />
                </div>
                <div className="accordion__item-right col-12 col-md-6 col-lg-7">
                  <img
                    src="img/picture/home/accordion-small.jpg"
                    width="348"
                    height="287"
                    alt="Concept"
                  />
                  <div className="accordion__item-text">
                    In the design process, they create both functional and
                    beautiful things. The team possesses unique individuality
                    and strong qualifications and can easily translate something
                    so abstract and visionary into a digital experience. They
                    always put the clients first no matter how complicated the
                    tasks are.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Service;
