import React, { Component, Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../Carousel/Carousel.css";

export default class Testimonials extends Component {
  render() {
    return (
      <Fragment>
        <div className="carousel-container works__container container container--size-large">
          <div class="row">
            {/* <div class="col-8" data-aos="fade-up"> */}
            <div class="col-8" data-aos="fade-up">
              <h2 class="works__title">Testimonials</h2>
            </div>
          </div>
          {/* <h1 className="carousel-header">TESTIMONIALS</h1> */}
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6100}
          >
            <div>
              <img
                src="../../../assets/img/picture/testimonials/bridget.png"
                alt=""
              />
              <div className="myCarousel">
                <h3>Bridget Oyefeso-Odusami</h3>
                <h4>Head, Marketing and Communications Stanbic IBTC</h4>
                <p>
                  We are committed to a long lasting relationship with PACE
                  Sports as we aim to grow the youth and empower them in their
                  passions
                </p>
              </div>
            </div>

            <div>
              <img
                src="../../../assets/img/picture/testimonials/perez.png"
                alt=""
              />
              <div className="myCarousel">
                <h3>Guillermo Perez-Castello</h3>
                <h4> MD, La Liga Nigeria</h4>
                <p>
                  PACE has shown continuous growth in the organization of HiFL;
                  from the finals of the first season which I was in the stadium
                  to watch down to the second season and you can tell with the
                  quality of the players and the games especially the super
                  four.
                </p>
              </div>
            </div>

            <div>
              <img
                src="../../../assets/img/picture/testimonials/charles.png"
                alt=""
              />
              <div className="myCarousel">
                <h3>Charles Nnochiri</h3>
                <h4>Head, Marketing, PZ Cussons Nigeria</h4>
                <p>
                  With HiFL, PACE Sports has a tournament that is properly run
                  and has the right kind of awareness to get collegiate football
                  in the country to where it should be
                </p>
              </div>
            </div>
            <div>
              <img
                src="../../../assets/img/picture/testimonials/ratan.png"
                alt=""
              />
              <div className="myCarousel">
                <h3>Ratan Varyani</h3>
                <h4>MD, Vista International Ltd</h4>
                <p>
                  PACE Sports has created a very interesting concept with HiFL
                  and we believe it has a tremendous scope and potential to grow
                  within the continent as a foremost youth empowerment program
                  through sports
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </Fragment>
    );
  }
}
