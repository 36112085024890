import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css"

function Contact() {
  useEffect(()=>{
    Aos.init({duration:2000})
  },[])
     
    return (
        <section className="contact-section">
        <div className="contact-section__container container container--size-large">
          <div className="row">
            <div className="contact-section__main col-12 col-lg">
              <h2 className="contact-section__title" data-aos="fade-up" >Contact</h2>
              <address className="contact-section__address" data-aos="fade-up" >7, Talabi Street, Adeniyi Jones Avenue, Ikeja, Lagos, Nigeria.</address>
              <div className="contact-section__link" data-aos="fade-up" >
              <a href="tel:+234 703 749 1080">+234 703 749 1080</a><br/>
              <a href="tel:+234(0)803 816 4092">+234(0)803 816 4092</a>
              </div>
              <div className="contact-section__link" data-aos="fade-up">
                <a href="mailto:info@centrix.com">info@psemltd.com</a>
              </div>
              <ul className="contact-section__social"data-aos="fade-up" >
                <li>
                  <a href="https://www.facebook.com/psemltd">Facebook</a>
                </li>
                <li>
                  <a href="https://www.twitter.com/psemltd">Twitter</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/psemltd/">Instagram</a>
                </li>
                <li>
                  <a href="http://www.linkedin.com/company/pace-sports-and-entertainment-marketing">LinkedIn</a>
                </li>
              </ul>
            </div>
            <div className="contact-section__aside col-12 col-lg" data-aos="fade-up">
              <div className="discuss-project discuss-project--no-padding col-12">
                <div className="discuss-project__wrapper" data-aos="fade-up" >
                  <div className="discuss-project__title" data-aos="fade-up" >Send request</div>
                  <form action="#" method="post">
                    <div className="row justify-content-between gx-0">
                      <div className="col-12" data-aos="fade-up" >
                        <label className="discuss-project__field field">
                          <input type="text" name="name" />
                          <span className="field__hint">Name</span>
                        </label>
                      </div>
                      <div className="col-12" data-aos="fade-up" >
                        <label className="discuss-project__field field">
                          <input type="email" name="email" />
                          <span className="field__hint">Email</span>
                        </label>
                      </div>
                      <div className="col-12" data-aos="fade-up">
                        <label className="discuss-project__field discuss-project__field--textarea field">
                          <textarea name="message" required></textarea>
                          <span className="field__hint">Message</span>
                        </label>
                      </div>
                      <div className="discuss-project__bottom col-12">
                        <button className="discuss-project__send btn--theme-black btn" type="submit" >
                          <span className="btn__text">Submit</span>
                          <span className="btn__icon">
                            {/* <svg width="19" height="19">
                              <use xlink:href="#link-arrow"></use>
                            </svg> */}
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Contact;