import React from 'react'
import Footer from '../layouts/Footer'
// import AboutHeader from '../layouts/AboutHeader'
import Content from '../sections/About/Content'
import Header from '../layouts/Header'


const AboutUs = () => {
  return (
    <div>
     <Header/>
     <Content/>
     <Footer/>
    </div>
  )
}

export default AboutUs
