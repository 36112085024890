import React, { Component, Fragment } from "react";
import About from "./About";
import Service from "./Service";
import Banner from "./Banner";
import Assets from "./Assets";
import Contact from "./Contact";
// import Assetcopy from './Assetcopy/js/index'
import Testimonials from "./Carousel/Carousel";

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        <About />
        <Service />
        <Assets />
        {/* <Assetcopy/> */}
        <Testimonials />
        <Contact />
      </Fragment>
    );
  }
}

export default Content;
