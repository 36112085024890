import React from 'react'
// import ContactHeader from '../layouts/contactHeader'
import Content from '../sections/Contact/Content'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'

const Contact = () => {
  return (
    <div>
    <Header/>
    <Content/>
    <Footer/>
    </div>
  )
}

export default Contact
