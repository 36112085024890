import React, { Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css/navigation";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
SwiperCore.use([Navigation, Pagination, Autoplay]);

function Assets() {
  return (
    <Fragment>
      <section className="works" id="assets">
        <div className="works__container container container--size-large">
          <header className="works__header">
            <div className="row">
              <div className="col-8" data-aos="fade-up">
                <h2 className="works__title">
                  <span>Look what</span>We do
                </h2>
              </div>
              <div className="col-4 text-md-right" data-aos="fade-up">
                <a
                  className="works__more arrow-link--white arrow-link"
                  href="projects-masonry.html"
                >
                  <span className="arrow-link__text">View all</span>
                </a>
              </div>
            </div>
          </header>
          <div
            className="works__carousel carousel swiper-container __js_carousel"
            data-aos="fade-up"
          >
            <div className="carousel__navigation">
              {/* <button
                className="carousel__btn carousel__btn--prev"
                type="button"
              >
                Prev
              </button> */}
              <h1
                className="carousel-prev"
                style={{
                  cursor: "pointer",
                  marginRight: "1rem",

                  fontSize: "2rem",
                }}
              >
                Prev
              </h1>
              {/* <button
                className="carousel__btn carousel__btn--next"
                type="button"
              >
                Next
              </button> */}
              <h1
                className="carousel-next"
                style={{
                  cursor: "pointer",
                  marginRight: "1rem",

                  fontSize: "2rem",
                }}
              >
                Next
              </h1>
            </div>
            {/* <div className="swiper-wrapper"> */}
            <Swiper
              style={{}}
              className="swiper-wrapper"
              spaceBetween={0}
              slidesPerView={2}
              navigation={{
                prevEl: ".carousel-prev",
                nextEl: ".carousel-next",
              }}
            >
              <SwiperSlide
                className="swiper-slider"
                style={{
                  overflow: "hidden",
                }}
              >
                <a
                  className="carousel__item carousel-card swiper-slide"
                  href="https://www.hiflng.com"
                  target="_blank"
                  style={{
                    overflow: "hidden",
                    bottom: "-5px",
                  }}
                >
                  <div>
                    <img
                      src="../assets/img/picture/carousel/hifl-pace.jpg"
                      width="662px"
                      height="510px"
                      style={{ overflow: "hidden", cursor: "pointer" }}
                      alt="Desk Issue 0.1"
                    />
                  </div>
                  <span className="carousel-card__bottom">
                    <span className="carousel-card__title">
                      Higher Institutions Football League
                    </span>
                  </span>
                </a>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide"
                style={{ overflow: "hidden" }}
              >
                {" "}
                <a
                  className="carousel__item carousel-card swiper-slide"
                  href="https://www.hiflng.com/unifest"
                  target="_blank"
                  style={{ overflow: "hidden", bottom: "-5px" }}
                >
                  <div>
                    <img
                      src="../assets/img/picture/carousel/unifest-pace.jpg"
                      width="662"
                      height="510"
                      alt="Desk Issue 0.1"
                    />
                  </div>
                  <span className="carousel-card__bottom">
                    <span className="carousel-card__title">HiFL Unifest</span>
                  </span>
                </a>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide"
                style={{ overflow: "hidden" }}
              >
                {" "}
                <a
                  className="carousel__item carousel-card swiper-slide"
                  href="https://www.hiflng.com/masterclass"
                  target="_blank"
                  style={{ overflow: "hidden", bottom: "-5px" }}
                >
                  <div>
                    <img
                      src="../assets/img/picture/carousel/masterclass-pace.jpg"
                      width="662"
                      height="510"
                      alt="Desk Issue 0.1"
                    />
                  </div>
                  <span className="carousel-card__bottom">
                    <span className="carousel-card__title">
                      HiFL Masterclass
                    </span>
                  </span>
                </a>
              </SwiperSlide>
              <SwiperSlide style={{ overflow: "hidden" }}>
                {" "}
                <a
                  className="carousel__item carousel-card swiper-slide"
                  href="https://hiflng.com/studentfranchise"
                  target="_blank"
                  style={{ overflow: "hidden", bottom: "-5px" }}
                >
                  <div>
                    <img
                      src="../assets/img/picture/carousel/franchise.jpg"
                      width="662"
                      height="510px"
                      alt="Desk Issue 0.1"
                    />
                  </div>
                  <span className="carousel-card__bottom">
                    <span className="carousel-card__title">Franchise</span>
                  </span>
                </a>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide"
                style={{ overflow: "hidden" }}
              >
                <a
                  className="carousel__item carousel-card swiper-slide"
                  target="_blank"
                  href="https://hiflng.com/gametime"
                  style={{ overflow: "hidden", bottom: "-5px" }}
                >
                  <div>
                    <img
                      src="../assets/img/picture/carousel/gametime.jpg"
                      width="662"
                      height="510"
                      alt="Desk Issue 0.1"
                    />
                  </div>
                  <span className="carousel-card__bottom">
                    <span className="carousel-card__title">Gametime</span>
                  </span>
                </a>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide"
                style={{ overflow: "hidden" }}
              >
                <a
                  className="carousel__item carousel-card swiper-slide"
                  href="https://hiflng.com/volunteer"
                  target="_blank"
                  style={{ overflow: "hidden", bottom: "-5px" }}
                >
                  <div>
                    <img
                      src="../assets/img/picture/carousel/volunteer-psem.jpg"
                      width="662"
                      height="510"
                      alt="Desk Issue 0.1"
                    />
                  </div>
                  <span className="carousel-card__bottom">
                    <span className="carousel-card__title">Volunteer</span>
                  </span>
                </a>
              </SwiperSlide>
            </Swiper>
            {/* </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Assets;
