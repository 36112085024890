/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Preloader
import Preloader from './components/layouts/Preloader'
// Pages
import Home from './components/pages/Home'
import Contact from './components/pages/Contact'
import AboutUs from './components/pages/AboutUs'

export default () => {
  return (
    <Router>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={AboutUs} />
      </Switch>
    </Router>
  )
}