import React from 'react'
// import Service from '../Home/Service'
// import Client from '../Home/Client'
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from 'react';

const Content = () => {
  useEffect(()=>{
    Aos.init({duration:2000})
  },[])
  return (
    <div>
      <main>
      <article className="about-us">
        <div className="first-banner about-us__banner">
          <div className="first-banner__image">
            <img src="../../../assets/img/picture/first-banner/banner-about.jpg" width="1920" height="1100" alt="" />
          </div>
          <div className="container container--size-large">
            <h1 className="first-banner__title heading heading--size-large">We connect
              <br/>
              <span>clients</span> with their <span>audience</span>
            </h1>
          </div>
        </div>
        <section className="about-video-section about-us__stat">
          <div className="about-video-section__container container container--size-large">
            <div className="row">
              <div className="about-video-section__main col-12 col-md">
                <div className="about-video-section__title" data-aos="fade-up">
                We recognize that passion-based marketing remains the most effective tool to reach, engage and convert. 
                We spot and spur trends that help us devise focused plans that inform our clients' compelling yet practical marketing strategies.<br /><br />
                 We tap into global insights and talent and continue to create and drive innovations & assets
                 that position our clients at the summit of their respective sectors using sports and entertainment. 
                </div>
                <div className="row align-items-end">
                  <div className="col-4 col-md-5 col-xl-4" data-aos="fade-up">
                    <a className="about-video-section__more arrow-link" href="about-us.html">
                      <span className="arrow-link__text">About us</span>
                      {/* <span className="arrow-link__icon">
                        <svg width="75" height="75">
                          <use xlink:href="#link-arrow"></use>
                        </svg>
                      </span> */}
                    </a>
                  </div>
                  <div className="col-8 col-md-7 col-xl-8" data-aos="fade-up">
                    <div className="about-video-section__text">We build, own and manage assets and products for users around the world who are interested in African sports, entertainment, and culture.</div>
                  </div>
                </div>
              </div>
              <div className="about-video-section__aside col-12 col-md order-first order-md-0" data-aos="fade-up">
                <div className="about-video-section__video video-block">
                  <img src="../../../assets/img/picture/home/feature-2.jpg" alt=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="about-video-section__statistics statistics">
            <div className="statistics__container container">
              <div className="row justify-content-between">
                <div className="statistics__item col-6 col-md-auto" data-aos="fade-up">
                  <div className="statistics__item-value">
                    <span className="__js_number" data-end-value="25">2</span>
                  </div>
                  <div className="statistics__item-text">managers</div>
                </div>
                <div className="statistics__item col-6 col-md-auto" data-aos="fade-up">
                  <div className="statistics__item-value">
                    <span className="__js_number" data-end-value="200">10</span>
                  </div>
                  <div className="statistics__item-text">the person
                    <br/>in the team
                  </div>
                </div>
                <div className="statistics__item col-6 col-md-auto" data-aos="fade-up">
                  <div className="statistics__item-value">
                    <span className="__js_number" data-end-value="15">7</span>
                  </div>
                  <div className="statistics__item-text">Years
                    <br/>experience
                  </div>
                </div>
                <div className="statistics__item col-6 col-md-auto" data-aos="fade-up">
                  <div className="statistics__item-value">
                    <span className="__js_number" data-end-value="7">3</span>
                  </div>
                  <div className="statistics__item-text">awards and
                    <br/>accolades
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          {/* <Service /> */}
          {/* <Client /> */}
        <section className="about-us__team-section team-section team-section--pb-0">
          <div className="team-section__container container container--size-large">
            <h2 className="team-section__title" data-aos="fade-up">Our magic team</h2>
            <div className="team-section__grid">
              <div className="team-section__item" data-aos="fade-up">
                <div className="member-card">
                  <div className="member-card__image">
                    <img src="../../../assets/img/picture/team/1.jpg" width="385" height="548" alt="Sam Flex photo"/>
                  </div>
                  <div className="member-card__info">
                    <div className="member-card__title">Sam Flex</div>
                    <div className="member-card__post">Director</div>
                     {/* Social */}
                    <ul className="member-card__social social--hover-white social">
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="linkedin icon">
                            {/* <use xlink:href="#linkedin"></use> */}
                          </svg>
                          <span className="visually-hidden">linkedin</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="facebook icon">
                            {/* <use xlink:href="#facebook"></use> */}
                          </svg>
                          <span className="visually-hidden">facebook</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="twitter icon">
                            {/* <use xlink:href="#twitter"></use> */}
                          </svg>
                          <span className="visually-hidden">twitter</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-section__item" data-aos="fade-up">
                <div className="member-card">
                  <div className="member-card__image">
                    <img src="../../../assets/img/picture/team/1.jpg" width="385" height="548" alt="Sam Flex photo"/>
                  </div>
                  <div className="member-card__info">
                    <div className="member-card__title">Sam Flex</div>
                    <div className="member-card__post">Director</div>
                     {/* Social */}
                    <ul className="member-card__social social--hover-white social">
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="linkedin icon">
                            {/* <use xlink:href="#linkedin"></use> */}
                          </svg>
                          <span className="visually-hidden">linkedin</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="facebook icon">
                            {/* <use xlink:href="#facebook"></use> */}
                          </svg>
                          <span className="visually-hidden">facebook</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="twitter icon">
                            {/* <use xlink:href="#twitter"></use> */}
                          </svg>
                          <span className="visually-hidden">twitter</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-section__item" data-aos="fade-up">
                <div className="member-card">
                  <div className="member-card__image">
                    <img src="../../../assets/img/picture/team/1.jpg" width="385" height="548" alt="Sam Flex photo"/>
                  </div>
                  <div className="member-card__info">
                    <div className="member-card__title">Sam Flex</div>
                    <div className="member-card__post">Director</div>
                     {/* Social */}
                    <ul className="member-card__social social--hover-white social">
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="linkedin icon">
                            {/* <use xlink:href="#linkedin"></use> */}
                          </svg>
                          <span className="visually-hidden">linkedin</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="facebook icon">
                            {/* <use xlink:href="#facebook"></use> */}
                          </svg>
                          <span className="visually-hidden">facebook</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="twitter icon">
                            {/* <use xlink:href="#twitter"></use> */}
                          </svg>
                          <span className="visually-hidden">twitter</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team-section__item" data-aos="fade-up">
                <div className="member-card">
                  <div className="member-card__image">
                    <img src="../../../assets/img/picture/team/1.jpg" width="385" height="548" alt="Sam Flex photo"/>
                  </div>
                  <div className="member-card__info">
                    <div className="member-card__title">Sam Flex</div>
                    <div className="member-card__post">Director</div>
                     {/* Social */}
                    <ul className="member-card__social social--hover-white social">
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="linkedin icon">
                            {/* <use xlink:href="#linkedin"></use> */}
                          </svg>
                          <span className="visually-hidden">linkedin</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="facebook icon">
                            {/* <use xlink:href="#facebook"></use> */}
                          </svg>
                          <span className="visually-hidden">facebook</span>
                        </a>
                      </li>
                      <li className="social__item">
                        <a className="social__link" href="/#" target="_blank">
                          <svg width="20" height="20" aria-label="twitter icon">
                            {/* <use xlink:href="#twitter"></use> */}
                          </svg>
                          <span className="visually-hidden">twitter</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
    </div>
  )
}

export default Content

