import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer __js_fixed-footer">
        <div className="footer__container container container--size-large">
          <div className="footer__grid">
            <a className="footer__logo" href="#/" style={{ padding: "0px" }}>
              {/* <svg width="59" height="242">
                                <use xlink:href="#vertical-logo"></use>
                            </svg> */}
              <img
                height="100%"
                src="../../../assets/img/picture/logo/logo.png"
                alt="logo"
              />
            </a>
            <div className="footer__phone">
              <a href="tel:+234 703 749 1080">+234 703 749 1080</a>

              {/* <a href="tel:+234 703 749 1080">+234 703 749 1080</a>
                            <a href="mailto:info@psemltd.com">info@psemltd.com</a> */}
            </div>
            <div className="footer__menu">
              <div className="footer__title">Quick Links</div>
              <ul className="footer__menu-list">
                <li className="footer__menu-item footer__menu-item--current">
                  <a className="footer__menu-link" href="#/">
                    Home
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a className="footer__menu-link" href="about-us.html">
                    About Us
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a className="footer__menu-link" href="blog-masonry.html">
                    Services
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a className="footer__menu-link" href="contact.html">
                    Assets
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a className="footer__menu-link" href="/contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__menu">
              <div className="footer__title">Follow</div>
              <ul className="footer__menu-list">
                <li className="footer__menu-item">
                  <a
                    className="footer__menu-link"
                    href="https://www.facebook.com/psemltd"
                  >
                    Facebook
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a
                    className="footer__menu-link"
                    href="https://www.twitter.com/psemltd"
                  >
                    Twitter
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a
                    className="footer__menu-link"
                    href="https://www.instagram.com/psemltd/"
                  >
                    Instagram
                  </a>
                </li>
                <li className="footer__menu-item">
                  <a
                    className="footer__menu-link"
                    href="http://www.linkedin.com/company/pace-sports-and-entertainment-marketing"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__feedback">
              <div className="footer__title">Sign up to our newsletter</div>
              <form className="footer__feedback-form" action="#" method="post">
                <label className="footer__feedback-field field">
                  <input type="email" name="email" />
                  <span className="field__hint">E-mail</span>
                </label>
                <button
                  className="footer__feedback-send arrow-btn arrow-btn--size-large"
                  type="button"
                >
                  <svg width="75" height="75">
                    {/* <use xlink:href="#link-arrow"></use> */}
                  </svg>
                </button>
              </form>
              <div className="footer__feedback-notice">
                ADDRESS: 7, Talabi Street, Adeniyi Jones Avenue, Ikeja, Lagos,
                Nigeria
              </div>
              {/* <div className="footer__feedback-notice">This site is protected by reCAPTHCHA and the <a href="#/" target="_blank">Google Privacy Policy</a> and <a href="#/" target="_blank">Terms of Service apply</a>.</div> */}
            </div>
            <div className="footer__bottom">
              <div className="footer__copyright">
                © PACE SPORTS AND ENTERTAIMENT MARKETING LTD. All Rights
                Resevered
              </div>
              <a className="footer__privacy" href="#/">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
