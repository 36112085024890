import React from "react";

function Banner() {
  return (
    <section className="hero">
      <div className="hero__bg __js_parallax">
        <img
          src="../../../assets/img/picture/home/hero.jpg"
          width="1920"
          height="1080"
          alt=""
        />
      </div>
      <div className="hero__container container container--size-large">
        <header className="hero__header">
          <div className="container container--size-large">
            <h1 className="hero__title">
              Passion <span>Pace</span>
              <span>Precision</span>
            </h1>
            {/* <a className="hero__more arrow-link" href="about-us.html">
                <span className="arrow-link__text">About us</span>
                <span className="arrow-link__icon">
                  <svg width="75" height="75">
                    <use xlink:href="#link-arrow"></use>
                  </svg>
                </span>
              </a> */}
          </div>
        </header>
        <div className="hero__content">
          <div className="hero__vertical vertical-logo">
            <div
              className="vertical-logo__layer vertical-logo__layer--yellow"
              style={{
                opacity: "1",
                width: "99px",
                height: "242px",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              <p
                style={{
                  transform: "rotate(-90deg)",
                  fontSize: "2rem",
                  fontWeight: "bold",
                }}
              >
                {" "}
                PACE
              </p>

              {/* <img
                src="../assets/img/picture/logo/logo.png"
                alt="logo"
                style={{
                  height: "100%",
                  weight: "100%",
                }}
              /> */}
              {/* <svg width="59" height="242">
                <use xlink:href="#vertical-logo"></use>
              </svg> */}
            </div>
            {/* <div className="vertical-logo__layer vertical-logo__layer--black">
              <svg width="59" height="242">
                <use xlink:href="#vertical-logo"></use>
              </svg>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
